import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function HeaderBack({ page, path, goBack = false, showBack = true }) {
  const history = useHistory();
  return (
    <div
      className="py-4 flex flex-row align-items-center justify-between"
      style={{ width: "100%" }}
    >
      {showBack ? (
        <div className="px-4 cursor-pointer">
          <button
            style={{ color: "#1cac87" }}
            className="hover:text-green-700 cursor-pointer font-semibold font-size-12"
            onClick={() => {
              if (goBack) {
                history.push(path);
              } else {
                history.push(path);
              }
            }}
          >
            Back
          </button>
        </div>
      ) : (
        <div className="px-4">
          <div style={{ width: "24px", height: "24px" }}></div>
        </div>
      )}
      <div className="flex justify-center">
        <span className="font-size-18 font-bold">{page}</span>
      </div>
      <div className="px-4">
        <div style={{ width: "24px", height: "24px" }}></div>
      </div>
    </div>
  );
}

export default HeaderBack;
