import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const PersonalInfoSkeleton = () => {
  return (
    <div className="p-4 no-scrollbar">
      <div className="d-flex justify-between align-items-center mb-4">
        <Skeleton circle width={30} height={30} />
        <Skeleton width="30%" height={30} />
      </div>

      <div className="d-flex flex-col align-items-center mb-4">
        <Skeleton circle width={100} height={100} />
        <Skeleton width={150} height={20} style={{ marginTop: "10px" }} />
      </div>

      {[...Array(7)].map((_, index) => (
        <div key={index} className="mb-3">
          <Skeleton width="30%" height={15} style={{ marginBottom: "8px" }} />
          <Skeleton width="100%" height={40} />
        </div>
      ))}

      <div
        className="fixed bottom-0 py-4 w-11/12"
        style={{ background: "#f7f7f7" }}
      >
        <Skeleton height={50} />
      </div>
    </div>
  );
};

export default PersonalInfoSkeleton;
