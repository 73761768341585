import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { BiFile } from "react-icons/bi";
import { BiFileBlank } from "react-icons/bi";
import { FaChevronRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";

function CreateInvoiceDrawer({ setCreateInvoiceModal }) {
  const history = useHistory();

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <span className="font-size-14 font-bold">Create Invoice</span>
        <CloseIcon
          fontSize="small"
          className="cursor-pointer"
          onClick={() => setCreateInvoiceModal(false)}
        />
      </div>
      <div
        className="mt-4 flex flex-row align-items-center justify-between cursor-pointer"
        onClick={() => {
          history.push("/simple-invoice");
          setCreateInvoiceModal(false);
        }}
      >
        <div className="flex flex-row align-items-center">
          <div
            className="p-2 rounded-full flex align-items-center justify-center"
            style={{
              background: "#F3F4F6",
              width: "40px",
              height: "40px",
              border: "1px solid #ecf2ff",
            }}
          >
            <BiFileBlank size={24} color="#020204" />
          </div>
          <div className="flex flex-col ml-2">
            <span className="font-size-12 font-semibold text-black">
              Simple Invoice
            </span>
            <span
              className="font-size-10 font-semibold"
              style={{ color: "#788187" }}
            >
              Quick and Clear: Your Service, Your Price{" "}
            </span>
          </div>
        </div>
        <FaChevronRight color="#788187" />
      </div>

      <div
        className="mt-4 flex flex-row align-items-center justify-between cursor-pointer"
        onClick={() => {
          history.push("/full-invoice");
          setCreateInvoiceModal(false);
        }}
      >
        <div className="flex flex-row align-items-center">
          <div
            className="p-2 rounded-full flex align-items-center justify-center"
            style={{
              background: "#F3F4F6",
              width: "40px",
              height: "40px",
              border: "1px solid #ecf2ff",
            }}
          >
            <BiFile size={24} color="#020204" />
          </div>
          <div className="flex flex-col ml-2">
            <span className="font-size-12 font-semibold text-black">
              Full Invoice
            </span>
            <span
              className="font-size-10 font-semibold"
              style={{ color: "#788187" }}
            >
              Every Detail, Every Dollar: A Complete Breakdown{" "}
            </span>
          </div>
        </div>
        <FaChevronRight color="#788187" />
      </div>
    </div>
  );
}

export default CreateInvoiceDrawer;
