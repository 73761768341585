import React, { useEffect, useState } from "react";
import PersonalInfoSkeleton from "../../../Loaders/PersonalInfoSkeleton";
import HeaderBack from "../FirstPage/HeaderBack";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Select from "react-select";
import { GetMyProfileAPI } from "../../../services/invoice";
import {
  GetIndustriesAPI,
  UpdateBusinessProfileAPI,
} from "../../../services/auth";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { notificationMessageFunction } from "../../../services/notificationConst";

const SignupSchema = Yup.object().shape({
  customerType: Yup.string().required("Customer type is required"),
  industry: Yup.string().required("Business industry is required"),
  industry_type: Yup.string().required("Business industry type is required"),
});

function AuthFlowOptions() {
  const [isLoading, setIsLoading] = useState(false);
  const [industriesList, setIndustriesList] = useState([]);
  const [userData, setUserData] = useState({});
  const history = useHistory();

  const getMyProfile = () => {
    setIsLoading(true);
    GetMyProfileAPI()
      .then((res) => {
        setIsLoading(false);
        const data = res.data.data.data;
        setUserData(data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getMyProfile();
  }, []);

  const getIndustriesAssets = () => {
    GetIndustriesAPI()
      .then((res) => {
        if (res.data.data.industries) {
          setIndustriesList(res.data.data.industries);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getIndustriesAssets();
  }, []);

  const handleSubmit = (values) => {
    setIsLoading(true);

    UpdateBusinessProfileAPI(values)
      .then((res) => {
        if (
          userData.invoice_deposit_wallet === undefined ||
          userData.invoice_deposit_wallet
        ) {
          history.push({
            pathname: `/invoice-wallet`,
            state: { isLogin: true },
          });
        } else {
          history.push({
            pathname: `/invoice-widget`,
            state: { isLogin: true },
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMessage = err.data.error.message;
        toast.error(notificationMessageFunction(errorMessage));
      });
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <PersonalInfoSkeleton />
      ) : (
        <div className="no-scrollbar">
          <HeaderBack
            page={"Business Info"}
            path=""
            goBack={true}
            showBack={false}
          />
          <div className="">
            <div className="mt-2" style={{ marginBottom: "40px" }}>
              <Formik
                initialValues={{
                  email: userData?.email || "",
                  customerType: userData?.customerType || "",
                  industry: userData?.industry || "",
                  industry_type: userData?.industry_type || "",
                  industry_risk: userData?.industry_risk || "",
                }}
                validationSchema={SignupSchema}
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mb-2 flex flex-col px-4">
                      <label
                        htmlFor="email"
                        className="font-size-12 mb-1 font-semibold text-gray-600"
                      >
                        Email*
                      </label>
                      <input
                        type="text"
                        value={values.email}
                        placeholder="Enter email"
                        onChange={handleChange}
                        name="email"
                        className={`input-field font-size-12 ${
                          errors.email && touched.email ? "error-border" : ""
                        }`}
                        disabled
                      />
                      {errors.email && touched.email && (
                        <div className="text-left mt-1 ml-2 text-danger font-size-12">
                          {errors.email}
                        </div>
                      )}
                    </div>

                    <div className="mb-2 flex flex-col px-4">
                      <label
                        htmlFor="email"
                        className="font-size-12 mb-1 font-semibold text-gray-600"
                      >
                        Customer Type*
                      </label>
                      <Select
                        styles={{
                          menuList: (provided) => ({
                            ...provided,
                            maxHeight: "300px",
                            fontSize: "12px",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 10000,
                          }),
                        }}
                        className={`select-input font-semibold text-gray-600 font-size-12 ${
                          errors.customerType && touched.customerType
                            ? "error-border"
                            : ""
                        }`}
                        placeholder="Select Customer Type"
                        classNamePrefix="select-input"
                        options={[
                          { label: "INDIVIDUAL", value: "INDIVIDUAL" },
                          { label: "BUSINESS", value: "BUSINESS" },
                        ]}
                        value={[
                          { label: "INDIVIDUAL", value: "INDIVIDUAL" },
                          { label: "BUSINESS", value: "BUSINESS" },
                        ].find((c) => c.value === values.customerType)}
                        onChange={(val) => {
                          if (val) {
                            setFieldValue("customerType", val.value);
                          } else {
                            setFieldValue("customerType", "");
                          }
                        }}
                        name="customerType"
                      />
                      {errors.customerType && touched.customerType && (
                        <div className="text-left mt-1 ml-2 text-danger font-size-12">
                          {errors.customerType}
                        </div>
                      )}
                    </div>

                    <div className="mb-2 flex flex-col px-4">
                      <label
                        htmlFor="email"
                        className="font-size-12 ml-1 mb-1 font-semibold text-gray-600"
                      >
                        Business Industry*
                      </label>
                      <Select
                        styles={{
                          menuList: (provided) => ({
                            ...provided,
                            maxHeight: "250px",
                            fontSize: "12px",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 10000,
                          }),
                        }}
                        className={`select-input font-semibold text-gray-600 font-size-12 ${
                          errors.industry && touched.industry
                            ? "error-border"
                            : ""
                        }`}
                        placeholder="Select Industry"
                        classNamePrefix="select-input"
                        options={industriesList}
                        value={industriesList.find(
                          (c) => c.value === values.industry
                        )}
                        onChange={(val) => {
                          if (val) {
                            setFieldValue("industry", val.value);
                            setFieldValue("industry_risk", val.risk);
                          } else {
                            setFieldValue("industry_risk", "");
                            setFieldValue("industry", "");
                          }
                        }}
                        name="industry"
                      />
                      {errors.industry && touched.industry && (
                        <div className="text-left mt-1 ml-2 text-danger font-size-12">
                          {errors.industry}
                        </div>
                      )}
                    </div>

                    <div className="mb-2 flex flex-col px-4">
                      <label
                        htmlFor="email"
                        className="font-size-12 ml-1 mb-1 font-semibold text-gray-600"
                      >
                        Business Type*
                      </label>
                      <Select
                        styles={{
                          menuList: (provided) => ({
                            ...provided,
                            maxHeight: "300px",
                            fontSize: "12px",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 10000,
                          }),
                        }}
                        className={`select-input font-semibold text-gray-600 font-size-12 ${
                          errors.industry_type && touched.industry_type
                            ? "error-border"
                            : ""
                        }`}
                        placeholder="Select Industry Type"
                        classNamePrefix="select-input"
                        options={[
                          { label: "Physical Goods", value: "physical_goods" },
                          {
                            label: "Digital Goods",
                            value: "digital_goods_services",
                          },
                        ]}
                        value={[
                          { label: "Physical Goods", value: "physical_goods" },
                          {
                            label: "Digital Goods",
                            value: "digital_goods_services",
                          },
                        ].find((c) => c.value === values.industry_type)}
                        onChange={(val) => {
                          if (val) {
                            setFieldValue("industry_type", val.value);
                          } else {
                            setFieldValue("industry_type", "");
                          }
                        }}
                        name="industry_type"
                      />
                      {errors.industry_type && touched.industry_type && (
                        <div className="text-left mt-1 ml-2 text-danger font-size-12">
                          {errors.industry_type}
                        </div>
                      )}
                    </div>

                    <div style={{ height: "20px" }}></div>
                    <div
                      className="fixed bottom-0 w-full flex flex-row justify-between py-4 px-5 align-items-center"
                      style={{ height: "60px", background: "#f7f7f7" }}
                    >
                      <button
                        id="login-button"
                        type="submit"
                        className="button-style mb-3"
                      >
                        <div className="font-size-12 font-bold">Next</div>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
      <Toaster position="bottom-center" reverseOrder={false} />
    </React.Fragment>
  );
}

export default AuthFlowOptions;
