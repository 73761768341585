import api from "../api/userApi";
import * as url from "../api/userURL";

export const GetInvoiceDetailsAPI = (invoice_code) => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.INVOICE_DETAILS + `?invoice_code=${invoice_code}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetMyProfileAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.ME)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getInvoiceSmartContractAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.DEPLOY_INVOICE)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deployInvoiceSmartContractAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.DEPLOY_INVOICE)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getInvoiceDepositAddressAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.INVOICE_DEPOSIT_WALLET)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const setInvoiceDepositAddressAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.INVOICE_DEPOSIT_WALLET, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export function extractFromAddress(address, first = 6) {
  if (address) {
    const firstSix = address.substring(0, first);
    const lastFour = address.substring(address.length - 4);

    return `${firstSix}...${lastFour}`;
  }
}

export const CreateInvoiceAPI = (payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.INVOICE, payload)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetInvoicesAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.INVOICE)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const IsInvoicePaidAPI = (invoice_code) => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.IS_INVOICE_PAID + `?invoice_code=${invoice_code}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const IsBankInvoicePaidAPI = (invoice_code) => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.IS_BANK_INVOICE_PAID + `?invoice_code=${invoice_code}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const InvoiceTransactionsAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.TRANSACTIONS)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetInvoiceChartDataAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.CHART_DATA)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
