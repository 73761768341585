import React, { startTransition, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { ThirdwebProvider } from "thirdweb/react";
import "./i18n";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

startTransition(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Suspense fallback={<div>Loading...</div>}>
        <ThirdwebProvider activeChain="mainnet">
          <Router>
            <App />
          </Router>
        </ThirdwebProvider>
      </Suspense>
    </React.StrictMode>,
    document.getElementById("root")
  );
});

serviceWorkerRegistration.register();
